const category_routes = [
  {
    path: "/category",
    name: "Category",
    component: () => import("../views/Dashboard/Category/Category-Home.vue"),
  },
  {
    path: "/category/create",
    name: "CategoryCreate",
    component: () => import("../views/Dashboard/Category/Category-Create.vue"),
  },

  {
    path: "/category/update/:id",
    name: "CategoryUpdate",
    component: () => import("../views/Dashboard/Category/Category-Create.vue"),
  },
];

export default category_routes;
