import axios from "axios";
import AuthService from "../services/auth";
import store from "../store";
import router from "../router";

export default function setup() {
	axios.interceptors.request.use(
		function (request) {
			if (!navigator.onLine) {
				console.log("in navigator");
				router.push("/error");
				return Promise.reject(request);
			}
			return request;
		},
		function (err) {
			console.log("error in request");
			return Promise.reject(err);
		}
	);

	axios.interceptors.response.use(
		function (response) {
			// Do something with response data
			return response;
		},
		function (error) {
			// Do something with response error
			if (!error.response) {
				console.log("Network error");
				router.push("/error");
				return Promise.reject(error);
      }
      
			if (error.response.status === 401) {
				console.log("UNAUTHORIZED");
				router.push("/login");
			}
			return Promise.reject(error);
		}
	);
}
