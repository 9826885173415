import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";


import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

// Google Maps
import VueGoogleMaps from "@fawmi/vue-google-maps";

// Lanaguage setup
import i18n from "./localization/i18n";
import InterceptorSetup from "./helpers/interceptor";


// Setup the axois intercept to detect token expiration
InterceptorSetup();

console.warn = () => {};

const main = createApp(App);



 

//  Import Plugins
main.use(i18n);
main.use(store);
main.use(router);
main.use(VueGoogleMaps, {
	load: {
		key: process.env.VUE_APP_GOOGLE_MAPS_API,
	},
});

main.mount("#app");
