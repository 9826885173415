const settings = JSON.parse(localStorage.getItem("settings"));

const defaultSettings = {
  language: "en",
};

const initialState = settings ? settings : defaultSettings;

function UpdateLocalSettings(state) {
  localStorage.setItem("settings", JSON.stringify(state));
}

const settingsModule = {
  namespaced: true,
  state: initialState,
  mutations: {
    setLanguage(state, language) {
      state.language = language;
      UpdateLocalSettings(state);
    },
    SettingsReset(state) {
      state = defaultSettings;
      UpdateLocalSettings(state);
    },
  },
  getters: {
    allSettings: (state) => {
      return state;
    },
    lang_setting: (state) => {
      return state.language;
    },
  },
  actions: {
    setLanguage({ commit }, language) {
      commit("setLanguage", language);
    },
    resetSettings({ commit }) {
      commit("SettingsReset");
    },
  },
};

export default settingsModule;
