import axios from "axios";
import authHeader from "../helpers/auth-header";

const API_ORDER_URL = process.env.VUE_APP_API_URL + "order";

class OrderService {
	getOrders(page, type = null, status = null) {
		// console.log("Get Orders");
		return axios.post(
			API_ORDER_URL + "/paginated/" + page,
			{
				type: type,
				status: status,
			},
			{ headers: { ...authHeader(), sort: 0 } }
		);
	}
	getSingleOrder(id) {
		return axios.get(API_ORDER_URL + "/admin/" + id, {
			headers: authHeader(),
		});
	}

	getSingleOrderInvoice(id) {
		return axios.get(API_ORDER_URL + "/admin/invoice/" + id, {
			headers: authHeader(),
		});
	}
	updateStatus(id, status) {
		console.log(status);
		return axios.patch(
			API_ORDER_URL + "/admin/" + id,
			{ status: status },
			{ headers: authHeader() }
		);
	}

	deleteOrder(id) {
		return axios.delete(API_ORDER_URL + "/admin/" + id, {
			headers: authHeader(),
		});
	}
}

export default new OrderService();
