import axios from "axios";

const API_AUTH_URL = process.env.VUE_APP_API_URL + "auth/admin";

class AuthService {
	// Takes a user and creates a post request on API_URL/loginjwt/
	login(user) {
		return axios
			.post(API_AUTH_URL + "/", {
				email: user.email,
				password: user.password,
			})
			.then((response) => {
				// Take token from response and store it in local storage

				if (response.data.data.token) {
					localStorage.setItem("user", JSON.stringify(response.data.data));
				}
				return response.data.data;
			});
	}

	logout() {
		localStorage.removeItem("user");
	}

	verifyToken(token) {
		return axios
			.post(API_AUTH_URL + "/verifytoken", {
				token: token,
			})
			.then(
				(response) => {
					console.log(response);
					return response.data.data;
				},

				(error) => {
					console.log(error);
				}
			);
	}
}

export default new AuthService();
