import { createStore } from "vuex";
import authModule from "./auth";
import settingsModule from "./settings";
import OrderModule from "./order";

export default createStore({
	modules: {
		auth: authModule,
		settings: settingsModule,
		orders: OrderModule,
	},
});
