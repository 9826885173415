<template>
	<router-view />
</template>

<script>
require("@/assets/styles/cherry-light.css");
require("@/assets/styles/ribbon.css");

export default {};
</script>

<style>
html,
body {
	height: 100%;
	margin: 0;
	padding: 0;
}

#app {
	height: 100%;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
</style>
