import AuthService from "../services/auth";

const user = JSON.parse(localStorage.getItem("user"));

const initialState = user
  ? { status: { loggedIn: true }, user }
  : { status: { loggedIn: false }, user: null };

const authModule = {
  namespaced: true,
  state: initialState,
  mutations: {
    loginSuccess(state, user) {
      state.status.loggedIn = true;
      state.user = user;
    },
    loginFail(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    logout(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    updateEmail(state, email) {
      state.user.email = email;
    },
  },
  getters: {
    getUser: (state) => {
      return state.user;
    },
  },
  actions: {
    // Attempt login
    login({ commit }, user) {
      return AuthService.login(user).then(
        (user) => {
          commit("loginSuccess", user);
          Promise.resolve(user);
        },
        (error) => {
          commit("loginFail");
          return Promise.reject(error);
        }
      );
    },
    logout({ commit }) {
      AuthService.logout();
      commit("logout");
    },
    updateEmail({ commit }, email) {
      commit("updateEmail", email);
    },
  },
};

export default authModule;
