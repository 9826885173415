<template>
	<div>
		<div class="notification p-4 d-flex justify-content-between">
			<div
				style="cursor: pointer"
				@click="
					() => {
						this.$router.push('/orders');
						this.$emit('hide');
					}
				"
			>
				<span class="p-2"><i class="fa fa-bell"></i> ({{ count }}) </span>
				New Orders!
			</div>
			<div style="cursor: pointer" @click="() => this.$emit('hide')">
				<i class="fa fa-times"></i>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	props: {
		count: {
			type: Number,
			default: 0,
		},
		time: {
			type: Number,
			default: 5,
		},
	},
	data() {
		return {
			interval: null,
		};
	},
	mounted() {
		this.interval = setInterval(() => {
			this.$emit("hide");
		}, this.time * 1000);
	},
	beforeUnmount() {
		clearInterval(this.interval);
	},
};
</script>

<style scoped>
.notification {
	background: white;
	border-radius: 10px;
	border: 1px black solid;
	position: absolute;
	z-index: 100;
}

@media only screen and (min-width: 768px) {
	.notification {
		width: 300px;
		right: 0;
		top: 72px;
	}
}
@media only screen and (max-width: 768px) {
	.notification {
		width: 250px;
		top: 105px;
		left: 50%;
		transform: translate(-50%, 0);
	}
}
</style>
