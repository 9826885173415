const notification_routes = [
	{
		path: "/notify",
		name: "Notification",
		component: () =>
			import("../views/Dashboard/Notification/Notification-Home.vue"),
	},
];

export default notification_routes;
