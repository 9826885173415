const order_routes = [
  {
    path: "/orders",
    name: "Orders",
    component: () => import("../views/Dashboard/Orders/Orders-Home.vue"),
  },
  {
    path: "/orders/:id",
    name: "SingleOrder",
    component: () => import("../views/Dashboard/Orders/Single-Order.vue"),
  },
  {
    path: "/orders/search",
    name: "OrderSearch",
    component: () => import("../views/Dashboard/Orders/Orders-Search.vue"),
  },
];

export default order_routes;
