import store from "../store";
import { createI18n } from "vue-i18n";

import english from "./english.json";
import amharic from "./amharic.json";

const messages = {
  en: english,
  amh: amharic,
};

const i18n = new createI18n({
  locale: store.state.settings.language, // set locale
  fallbackLocale: "amh", // set fallback locale
  messages, // set locale messages
});

export default i18n;
