<template>
	<div class="bg-light">
		<!-- Notification View -->
		<transition name="ntf-transition">
			<Notification v-if="show_notification" :count="new_orders.length" @hide="hideNotification" />
		</transition>

		<!-- Navbar -->
		<Navbar />

		<!-- Responsive Sidebar Toggler -->
		<div class="d-md-none d-block p-3 text bg-white" style="cursor: pointer" @click="toggleSidebar">
			<i class="fa fa-bars" aria-hidden="true"></i>
		</div>

		<!-- Main -->
		<main class="pl-0 pr-0 m-0 container-fluid">
			<div class="row" style="height: inherit">
				<!-- Sidebar -->
				<div class="col-lg-2 col-md-1 p-0 d-md-block" :class="sidebar_display ? '' : 'd-none'"
					style="height: inherit">
					<Sidebar @sidebar_click="toggleSidebar" />
				</div>

				<!-- Main Content -->
				<div class="container col-lg-10 col-md-11 p-0 d-md-block bg-white h-100 sidebar-content"
					:class="sidebar_display ? 'd-none' : ''" style="overflow: auto">
					<!-- Dashboard routes-->
					<router-view v-slot="{ Component }">
						<transition name="route" mode="out-in">
							<component :is="Component" />
						</transition>
					</router-view>
				</div>
			</div>
		</main>
	</div>
</template>

<script>
import { mapGetters } from "vuex";

// @ is an alias to /src
import Navbar from "@/components/Navbar";
import Sidebar from "@/components/Sidebar";
import Notification from "@/components/Notification";

export default {
	name: "Home",
	components: {
		Navbar,
		Sidebar,
		Notification,
	},
	data() {
		return {
			sidebar_display: false,
			notification_interval: null,
		};
	},
	methods: {
		toggleSidebar() {
			this.sidebar_display = !this.sidebar_display;
		},
		hideNotification() {
			console.log("please work");
			this.$store.dispatch("orders/hideNotification");
		},
	},
	computed: {
		...mapGetters({
			new_orders: "orders/newOrders",
			show_notification: "orders/showNotification",
		}),
	},
	mounted() {
		// Get Initial orders
		this.$store.dispatch("orders/getOrders");

		// Start Order Polling every 10 seconds
		// TODO: Possibly setup websocket implementation

		this.notification_interval = setInterval(() => {
			this.$store.dispatch("orders/getOrders");
		}, 10000);
	},
};
</script>

<style scoped>
/* TODO: Add option to disable animation */
/* Route transitions */
.route-enter-from {
	opacity: 0;
	transform: translateY(100px);
}

.route-enter-active {
	transition: all 0.3s ease;
}

.route-leave-to {
	opacity: 0;
	transform: translateY(-100px);
}

.route-leave-active {
	transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

/* Notification transitions */
.ntf-transition-enter-from {
	opacity: 0;
	transform: translateY(-100px);
}

.ntf-transition-enter-active {
	transition: all 0.5s ease;
}

.ntf-transition-leave-to {
	opacity: 0;
	transform: translateX(-100px);
}

.ntf-transition-leave-active {
	transition: all 0.5s ease;
}

/* Handle responsive */
@media only screen and (min-width: 768px) {
	main {
		height: calc(100vh - 72px);
	}
}

@media only screen and (max-width: 768px) {
	main {
		height: calc(100vh - 128px);
	}
}
</style>
