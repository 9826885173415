import OrderService from "@/services/order.js";

// Last order date created + extra
const last_check = JSON.parse(localStorage.getItem("last_check"));

function storeLastCheck(lastCheck) {
	localStorage.setItem("last_check", JSON.stringify(lastCheck));
}

function getNewOrders(orders, lastOrder) {
	return orders.filter((order) => {
		let orderDate = new Date(order.created_at);
		return orderDate > lastOrder;
	});
}

const OrderModule = {
	namespaced: true,
	state: {
		orders: [],
		pages: 0,
		last_check_date: last_check ? last_check : new Date("09/22/2022"),
		last_update: null,
		show_notification: false,
		unread: 0,
	},
	mutations: {
		// update the order and set the last update time to now
		updateOrder(state, orderData) {
			state.orders = orderData.orders;
			state.pages = orderData.total_pages;
			state.last_update = new Date();

			let newOrders = getNewOrders(orderData.orders, state.last_check_date);

			// If there are new orders and there are more new orders than previously seen
			if (newOrders.length > 0 && newOrders.length != state.unread) {
				console.log("New order detected");
				state.show_notification = true;
				state.unread = newOrders.length;
			}
		},
		hideNotification(state) {
			state.show_notification = false;
		},

		updateLastCheck(state, lastDate) {
			storeLastCheck(lastDate);
			state.last_check_date = lastDate;
			state.unread = 0;
		},
	},
	getters: {
		// Filter out the order that the user hasn't seen
		newOrders: (state) => {
			return getNewOrders(state.orders, state.last_check_date);
		},
		showNotification: (state) => {
			return state.show_notification;
		},
		firstPage: (state) => {
			return { orders: state.orders, pages: state.pages };
		},
		lastUpdate: (state) => {
			return state.last_update;
		},
	},
	actions: {
		getOrders({ commit }) {
			return OrderService.getOrders(1).then(
				(response) => {
					commit("updateOrder", response.data.data);
					// console.log(response);
					Promise.resolve(response);
				},
				(error) => {
					Promise.reject(error);
				}
			);
		},
		hideNotification({ commit }) {
			commit("hideNotification");
		},
		updateLastCheck({ commit }, lastCheckDate) {
			commit("updateLastCheck", lastCheckDate);
		},
	},
};

export default OrderModule;
