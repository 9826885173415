const inventory_routes = [
  // Show Inventory List
  {
    path: "",
    name: "Inventory",
    component: () => import("../views/Dashboard/Inventory/Inventory-Home.vue"),
  },
  // Show Product
  {
    path: "/inventory/product/:id",
    name: "InventoryProduct",
    component: () =>
      import("../views/Dashboard/Inventory/Inventory-Product.vue"),
  },
  // Create a Product
  {
    path: "/inventory/create",
    name: "CreateProduct",
    component: () =>
      import("../views/Dashboard/Inventory/Inventory-CreateProduct.vue"),
  },
  // Update a Product
  {
    path: "/inventory/update/:id",
    name: "UpdateProduct",
    component: () =>
      import("../views/Dashboard/Inventory/Inventory-CreateProduct.vue"),
  },
  // Import products
  {
    path: "/inventory/import",
    name: "ImportProducts",
    component: () =>
      import("../views/Dashboard/Inventory/Inventory-Import.vue"),
  },
  // Search
  {
    path: "/search",
    name: "Search",
    component: () =>
      import("../views/Dashboard/Inventory/Inventory-Search.vue"),
  },
];

export default inventory_routes;
