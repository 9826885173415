<template>
	<!-- Add hover text -->
	<div class="theme-background-secondary h-100 sidebar-content" style="overflow: auto">
		<span class="sidebarTooltip d-lg-none d-md-block d-sm-none" :style="{
			top: tooltipY + 'px',
			left: tooltipX + 'px',
			visibility: tooltipActive ? 'visible' : 'hidden'
		}">
			{{ tooltipText }}
		</span>
		<!-- Sidebar Top -->
		<div class="text-center d-flex align-items-center" style="height: 270px">
			<div class="w-100">
				<div class="py-3">
					<div class="p-2">
						<img src="@/assets/images/WhiteDebol.svg" style="max-height: 100px; width: 100%" />
					</div>

					<h4 class="d-md-none d-lg-block d-block">
						{{ $t("sidebar_title") }}
					</h4>
				</div>
				<div class="mx-auto d-lg-block d-md-none d-block" style="max-width: 140px">
					<button
						class="py-2 theme-button theme-background-light d-flex justify-content-center align-items-center"
						@click="CreateProduct">
						<span class="d-flex flex-column justify-content-center m-2">
							<i class="fa fa-plus" aria-hidden="true"></i>
						</span>
						<span class="d-lg-block d-md-none d-block">
							<p class="m-0 p-0" style="font-size: 14px">
								{{ $t("sidebar_create_product") }}
							</p>
						</span>
					</button>
				</div>
			</div>
		</div>
		<hr class="mx-auto m-0 p-0" style="width: 75%" />
		<!-- Sidebar Content -->
		<div class="sidebar-content" style="overflow-y: auto">

			<div v-for="(sidebar_link, index) in sidebar_links" :key="index">

				<!-- Top Level Sidebar Links-->
				<router-link class="d-block px-2 py-3 sidebar-item"
					:class="isName(sidebar_link.name) ? 'sidebar-link-active' : 'sidebar-link'" :to="sidebar_link.path"
					@click="emitClick(sidebar_link)"
					@mouseenter="sidebarHover($event, getReference(sidebar_link.title))" @mouseleave="sidebarHoverOut">

					<div class="d-flex justify-content-between">
						<!-- Sidebar info -->
						<div>
							<span class="mx-2"><i :class="sidebar_link.icon"></i></span>
							<span class="d-lg-inline d-md-none d-inline">{{ getReference(sidebar_link.title) }}
							</span>

						</div>

						<!-- Sidebar expand sublinks -->
						<div v-if="sidebar_link.sublink">
							<i class="fa" :class="
								isNameOrChild(sidebar_link.name, sidebar_link.sublink)
									? 'fa-chevron-down'
									: 'fa-chevron-right'
							" aria-hidden="true"></i>
						</div>
					</div>
				</router-link>

				<!-- Sidebar SubLinks-->
				<div v-if="
					sidebar_link.sublink && isNameOrChild(sidebar_link.name, sidebar_link.sublink)
				" class="mx-4">
					<div v-for="(sublink_link, sublink_index) in sidebar_link.sublink" :key="sublink_index">
						<router-link class="d-block px-2 py-3"
							:class="isName(sublink_link.name) ? 'sidebar-link-active' : 'sidebar-link'"
							:to="sublink_link.path" @click="emitClick(sublink_link)">
							{{ getReference(sublink_link.title) }}
						</router-link>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "Sidebar",
	data() {
		return {
			sidebar_links: [
				{
					icon: "fas fa-chart-line",
					title: "sidebar_dashboard_link",
					name: "Home",
					path: "/",
				},
				{
					icon: "fas fa-warehouse",
					title: "sidebar_inventory_link_search",
					name: "Search",
					path: "/search",
				},
				{
					icon: "fas fa-shopping-cart",
					title: "sidebar_inventory_create_product_link",
					name: "CreateProduct",
					path: "/inventory/create",
				},
				{
					icon: "fas fa-shopping-cart",
					title: "sidebar_inventory_import_product_link",
					name: "ImportProducts",
					path: "/inventory/import",
				},
				{
					icon: "fas fa-folder-open",
					title: "sidebar_category",
					name: "Category",
					path: "/category",
				},

				{
					icon: "fas fa-folder-open",
					title: "sidebar_category_create",
					name: "CategoryCreate",
					path: "/category/create",
				},
				{
					icon: "fas fa-bags-shopping",
					title: "sidebar_orders",
					name: "Orders",
					path: "/orders",
				},
				{
					icon: "fas fa-search",
					title: "sidebar_orders_search",
					name: "OrderSearch",
					path: "/orders/search",
				},
				{
					icon: "fas fa-truck",
					title: "sidebar_drivers",
					name: "Drivers",
					path: "/drivers",
				},
				{
					icon: "fas fa-cog",
					title: "sidebar_settings",
					name: "Settings",
					path: "/settings",
				},
				{
					icon: "fas fa-hands-helping",
					title: "sidebar_help",
					name: "Help",
					path: "/help",
				},
				{
					icon: "fas fa-bell",
					title: "sidebar_notification",
					name: "Notification",
					path: "/notify",
				},
			],
			tooltipX: 0,
			tooltipY: 0,
			tooltipText: "",
			tooltipActive: false,
		};
	},

	methods: {
		isName(name) {
			return this.$route.name === name;
		},
		isChildName(sublinks) {
			for (let i = 0; i < sublinks.length; i++) {
				if (this.$route.name === sublinks[i].name) {
					return true;
				}
			}
			return false;
		},
		isNameOrChild(name) {
			return this.isName(name);
		},

		CreateProduct() {
			this.$router.push("/inventory/create");
			this.emitClick("/inventory/create");
		},

		getReference(value) {
			return this.$t(value);
		},
		emitClick(link) {
			this.$emit("sidebar_click", link);
		},
		sidebarHover(event, text) {
			console.log(event.pageX, event.pageY, text);
			this.tooltipX = event.pageX;
			this.tooltipY = event.pageY;
			this.tooltipText = text;
			this.tooltipActive = true;
		},
		sidebarHoverOut() {
			this.tooltipActive = false;
			console.log("leave")
		}
	},
};
</script>
<style>
.sidebarTooltip {
	/* visibility: hidden; */
	width: 120px;
	background-color: black;
	color: #fff;
	text-align: center;
	border-radius: 6px;
	padding: 5px 0;

	/* Position the tooltip */
	position: absolute;
	z-index: 50;
}
</style>
