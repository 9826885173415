const drivers_routes = [
	{
		path: "/drivers",
		name: "Drivers",
		component: () =>
			import("../views/Dashboard/Drivers/Drivers-Home.vue"),
	},
];

export default drivers_routes;
