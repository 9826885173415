<template>
	<nav
		class="theme-background-primary navbar navbar-expand justify-content-between shadow-sm navbar-dark px-5 py-3"
	>
		<router-link class="brand-text navbar-brand" to="/">
			{{ $t("navbar_title") }} |
			<p class="m-0 p-0 d-inline text-dark">{{ navbar_subtitle }}</p>
		</router-link>
		<div class="d-flex align-items-center">
			<!-- <div class="mx-3">
				<i class="fa fa-bell" aria-hidden="true"></i>
			</div> -->
			<a
				class="nav-link theme-text-primary"
				style="cursor: pointer"
				@click="logout"
			>
				{{ $t("navbar_logout") }}
			</a>
		</div>
	</nav>
</template>

<script>
export default {
	name: "Navbar",
	methods: {
		logout() {
			this.$store.dispatch("auth/logout");
			this.$router.push("/login");
		},
	},
	computed: {
		navbar_subtitle() {
			let today = new Date();
			if (
				today.getMonth() == 0 &&
				today.getDate() >= 6 &&
				today.getDate() <= 8
			) {
				return "መልካም ገና 🎄";
			}
			return this.$t("navbar_subtitle");
		},
	},
};
</script>

<style>
.brand-text {
	font-weight: bold;
}
</style>
