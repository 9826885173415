import {
	createRouter,
	createWebHistory,
	createWebHashHistory,
} from "vue-router";
import Home from "../views/Home.vue";

// Sub routes
import inventory_routes from "./inventory_routes";
import order_routes from "./order_routes";
import category_routes from "./category_routes";
import drivers_routes from "./drivers_routes";
import settings_routes from "./settings_routes";
import help_routes from "./help_routes";
import notification_routes from "./notification_routes";
const routes = [
	{
		path: "/login",
		name: "Login",
		component: () => import("../views/Login.vue"),
	},
	{
		path: "/forgotPassword",
		name: "ForgotPassword",
		component: () => import("../views/ForgotPassword.vue"),
	},
	{
		path: "/",
		name: "Home",
		component: Home,
		children: [
			// Dashboard Homescreen - stats and charts
			{
				path: "/",
				name: "Home",
				component: () => import("../views/Dashboard/Dashboard-Home.vue"),
			},
			//Inventory
			{
				path: "/inventory",
				name: "Inventory",
				component: () => import("../views/Dashboard/Dashboard-Inventory.vue"),
				children: inventory_routes,
			},
			// Orders
			{
				path: "/orders",
				name: "Orders",
				component: () => import("../views/Dashboard/Dashboard-Orders.vue"),
				children: order_routes,
			},

			// Categories
			{
				path: "/category",
				name: "Category",
				component: () => import("../views/Dashboard/Dashboard-Category.vue"),
				children: category_routes,
			},
			// Drivers
			{
				path: "/drivers",
				name: "Drivers",
				component: () => import("../views/Dashboard/Dashboard-Drivers.vue"),
				children: drivers_routes,
			},
			// Settings
			{
				path: "/settings",
				name: "Settings",
				component: () =>
					import("../views/Dashboard/Settings/Settings-Home.vue"),
				children: settings_routes,
			},
			// Help
			{
				path: "/help",
				name: "Help",
				component: () => import("../views/Dashboard/Dashboard-Help.vue"),
				children: help_routes,
			},
			// Notification
			{
				path: "/notify",
				name: "Notification",
				component: () =>
					import("../views/Dashboard/Dashboard-Notification.vue"),
				children: notification_routes,
			},
		],
	},
	{
		path: "/error",
		name: "Error",
		component: () => import("../views/Error.vue"),
	},
];
const router = createRouter({
	history: process.env.IS_ELECTRON
		? createWebHashHistory()
		: createWebHistory(process.env.BASE_URL),
	routes,
});

// Prevent Unauthenticated Users from logging in

router.beforeEach((to, from, next) => {
	// redirect to login page if not logged in and trying to access a restricted page
	try {
		const publicPages = ["/login", "/forgotPassword", "/error"];
		const authRequired = !publicPages.includes(to.path);
		const loggedIn = localStorage.getItem("user");

		if (authRequired && !loggedIn) {
			return next("/login");
		}

		next();
	} catch (e) {
		alert("reached here inside exception");
		this.$router.push("/");
	}
});

export default router;
